@font-face {
  font-family: "PublicSans";
  font-weight: 400;
  font-style: normal;
  src: local("PublicSans"), url("PublicSans-VariableFont_wght.ttf") format("truetype");
}
@font-face {
  font-family: "PublicSans";
  font-weight: 500;
  font-style: normal;
  src: local("PublicSans"), url("PublicSans-VariableFont_wght.ttf") format("truetype");
}
@font-face {
  font-family: "PublicSans";
  font-weight: 600;
  font-style: normal;
  src: local("PublicSans"), url("PublicSans-VariableFont_wght.ttf") format("truetype");
}
@font-face {
  font-family: "PublicSans";
  font-weight: 700;
  font-style: normal;
  src: local("PublicSans"), url("PublicSans-VariableFont_wght.ttf") format("truetype");
}


@font-face {
  font-family: "CircularStd";
  font-weight: 400;
  font-style: normal;
  src: local("CircularStd"), url("CircularStd-Book.otf") format("opentype");
}
@font-face {
  font-family: "CircularStd";
  font-weight: 500;
  font-style: normal;
  src: local("CircularStd"), url("CircularStd-Medium.otf") format("opentype");
}
@font-face {
  font-family: "CircularStd";
  font-weight: 700;
  font-style: normal;
  src: local("CircularStd"), url("CircularStd-Bold.otf") format("opentype");
}

#brandingLogo {
  display: none;
}

@font-face {
  font-family: "Satoshi";
  src: url("Satoshi-Variable.woff2") format("woff2"),
    url("Satoshi-Variable.woff") format("woff"),
    url("Satoshi-Variable.ttf") format("truetype");
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi-VariableItalic";
  src: url("Satoshi-VariableItalic.woff2") format("woff2"),
    url("Satoshi-VariableItalic.woff") format("woff"),
    url("Satoshi-VariableItalic.ttf") format("truetype");
  font-weight: 300 900;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "galano_grotesque_aGalano Grotesque Altltthin";
  src: url("galano_grotesque_alt_thin-webfont.woff2") format("woff2"),
    url("galano_grotesque_alt_thin-webfont.woff") format("woff");
  font-weight: 100;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Galano Grotesque Alt";
  src: url("galano_grotesque_alt_light-webfont.woff2") format("woff2"),
    url("galano_grotesque_alt_light-webfont.woff") format("woff");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Galano Grotesque Alt";
  src: url("galano_grotesque_alt-webfont.woff2") format("woff2"),
    url("galano_grotesque_alt-webfont.woff") format("woff");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Galano Grotesque Alt";
  src: url("galano_grotesque_alt_medium-webfont.woff2") format("woff2"),
    url("galano_grotesque_alt_medium-webfont.woff") format("woff");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Galano Grotesque Alt";
  src: url("galano_grotesque_alt_semibold-webfont.woff2") format("woff2"),
    url("galano_grotesque_alt_semibold-webfont.woff") format("woff");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Galano Grotesque Alt";
  src: url("galano_grotesque_alt_bold-webfont.woff2") format("woff2"),
    url("galano_grotesque_alt_bold-webfont.woff") format("woff");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Galano Grotesque Alt";
  src: url("galano_grotesque_alt_extrabold-webfont.woff2") format("woff2"),
    url("galano_grotesque_alt_extrabold-webfont.woff") format("woff");
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Galano Grotesque Alt";
  src: url("galano_grotesque_alt_heavy-webfont.woff2") format("woff2"),
    url("galano_grotesque_alt_heavy-webfont.woff") format("woff");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}
